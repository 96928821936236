import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from '../Pages/Home'
import CheckMeter from '../Pages/CheckMeter'


export default function IndexRoutes() {
  return (
    <BrowserRouter>
        <Routes>
            <Route path='/home' element={<Home/>}/>
            <Route path='/' element={<CheckMeter/>}/>
          
        </Routes>
    </BrowserRouter>
  )
}
