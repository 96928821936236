import { Box, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import Button from './Button'

export default function DetailModal({ isOpen, onClose, Data }) {

    console.log("data", Data)
    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered size='lg'>
            <ModalOverlay />
            <ModalContent>
                {/* <ModalHeader></ModalHeader> */}
                {/* <ModalCloseButton /> */}
                <ModalBody>

                        <Box mt="32px">
                       
                        <Text fontSize={"16px"} mt="32px" textTransform={"uppercase"} color={"#242424"} fontWeight={"700"}>KCT Tokens</Text>

                        <Stack spacing={"15px"} mt="10px"> 
                            <HStack>
                                <Text fontSize={"15px"} textTransform={""} color={"#242424"} fontWeight={"600"}>KCT 1: </Text>
                                <Text color={"#aafff"} fontSize={"14px"}>{Data?.kct1} </Text>

                            </HStack>
                            <HStack>
                                <Text fontSize={"15px"} textTransform={""} color={"#242424"} fontWeight={"600"}>KCT 2: </Text>
                                <Text color={"#aafff"} fontSize={"14px"}>{Data?.kct2} </Text>

                            </HStack>
                          
                        </Stack>

                        <Text fontSize={"16px"} mt="32px" textTransform={"uppercase"} color={"#242424"} fontWeight={"700"}>Meter info</Text>

                        <Stack spacing={"15px"} mt="10px"> 
                            <HStack>
                                <Text fontSize={"15px"} textTransform={""} color={"#242424"} fontWeight={"600"}>Meter Number: </Text>
                                <Text color={"#aafff"} fontSize={"14px"}>{Data?.meternumber} </Text>

                            </HStack>
                            <HStack>
                                <Text fontSize={"15px"} textTransform={""} color={"#242424"} fontWeight={"600"}>Meter Make: </Text>
                                <Text color={"#aafff"} fontSize={"14px"}>{Data?.metermake} </Text>

                            </HStack>
                           
                            {/* <HStack>
                                <Text fontSize={"15px"} textTransform={""} color={"#242424"} fontWeight={"600"}>Tariff: </Text>
                                <Text color={"#aafff"} fontSize={"14px"}>{Data?.tariff} </Text>

                            </HStack>
                            <HStack>
                                <Text fontSize={"15px"} textTransform={""} color={"#242424"} fontWeight={"600"}>SPN: </Text>
                                <Text color={"#aafff"} fontSize={"14px"}>{Data?.spn} </Text>

                            </HStack> */}
                        </Stack>


                        <Button mt={"20px"} onClick={onClose}>Close</Button>

                        <Text fontSize={"15px"} mt="32px" textTransform={""} color={"#242424"} fontWeight={"700"} fontFamily={"sans-serif"}>
                            If the KCT has been successfully applied on your meter before, there would be no need to repeat the process.</Text>

                        <Text fontSize={"15px"} mt="12px" textTransform={""} color={"#242424"} fontWeight={"700"} fontFamily={"sans-serif"}>
                        For complaints, kindly reach out to us via our customer care e-mail and contact line <Box as="span" color={"blue"} cursor={"pointer"}>customercare@ekedp.com</Box>  and contact <Box color={"blue"} as="span" cursor={"pointer"}>07080671170 07001235666</Box>
                         </Text>




                    </Box>
             
                  
                </ModalBody>

                <ModalFooter>

                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
