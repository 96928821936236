import { Box, Flex, Image, Text, useDisclosure } from '@chakra-ui/react'
import React, { useState } from 'react'
import Input from '../Components/Input'
import { AiOutlineFieldNumber } from 'react-icons/ai'
import Button from '../Components/Button';
import DetailModal from '../Components/DetailModal';
import { GetKCTDataAPI } from '../Utils/ApiCall';
import Seo from '../Utils/Seo';

export default function CheckMeter() {
    const { isOpen, onOpen, onClose } = useDisclosure()

    const [MeterNo, setMeterNo] = useState("");
    const [Loading, setLoading] = useState(false);
    const [Data, setData] = useState(null);
    const [ErrorMSG, setErrorMSG] = useState({
        status: false,
        msg: ""
    });
    const [RedirectMsg, setRedirectMsg] = useState({
        status: false,

    });


    const handleBtn = async () => {
        setData(null)
        setLoading(true)
        setRedirectMsg({
            status: false,
        })

        try {

            const result = await GetKCTDataAPI({
                meternumber: MeterNo
            });
            console.log("result", result);

            if (result.data.status === true) {
                setMeterNo("")
                setErrorMSG({
                    status: false,
                    msg: result.data.msg
                })
                setLoading(false)
                setData(result.data.result)
                if (result.data.result.kct1 && result.data.result.kct2) {

                    onOpen()
                } else {

                    setRedirectMsg({
                        status: true,
                    })
                }


            } else {
                setLoading(false)
                setErrorMSG({
                    status: true,
                    msg: "Meter number not found ! "
                })
            }

        } catch (e) {
            console.log(e)
        }

     



    }

    return (
        <Box>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} bg="#01022E" px={"4%"} py="12px" >
                {/* <Box textAlign={"center"}>
                    <Text mt="10px" fontSize={["16px", "17px", "18px", "19px"]} color={"#fff"} fontWeight={"500"} fontFamily={"sans-serif"}>Request for meters and monitor your application progress
                    </Text>
                </Box> */}
                <a href='https://ekedp.com/' target='blank'>

                    <Image src='/logo.png' width={"80px"} />
                </a>
                <Text fontSize={["14px", "14px", "16px", "20px"]} color={"#fff"} fontWeight={"600"} fontFamily={"sans-serif"}></Text>
            </Box>

            <Flex justifyContent={"center"} flexWrap={"wrap"}>
                <Seo title='Customer KCT Info' description='EKEDC Customer KCT Info' />

                <Box bg="#fff" px={"4%"} w={["100%", "100%", "70%", "50%",]} py="32px" textAlign={"center"}>


                    <Text mt={["23vh", "23vh", "23vh", "18vh"]} lineHeight={"34px"} fontSize={["28px", "30px", "33px", "33px"]} fontWeight={"700"} fontFamily={"sans-serif"}>Obtain your STS -2 Migration Tokens</Text>
                    <Text mt={"10px"} color="red" lineHeight={"34px"} textTransform={"uppercase"} fontSize={["20px", "20px", "25px", "25px"]} fontWeight={"900"} fontFamily={"sans-serif"}>Upgrading your meter is free !</Text>
                    <Text mt="8px" fontSize={["0.9em", "1em", "1em", "1em"]} fontWeight={"400"} color="#7D838E" fontFamily={"sans-serif"}>Enter your meter number below and click the search button</Text>
                    <Box mt="32px">

                        <Input isDisabled={false} leftIcon={<AiOutlineFieldNumber />} bColor='#01022E' iconColor="#01022E" labelBg='#fff' label={"Meter Number"} id="identity" type='text' value={MeterNo} val={MeterNo != "" ? true : false} onChange={(e) => setMeterNo(e.target.value)} />
                        <Button disabled={false} isLoading={Loading} onClick={handleBtn} mt={"32px"}>Search</Button>

                        {/* <Box textAlign={"left"}       w={"100%"} rounded={"10px"} mt="10px">
                            <Text textAlign={"center"} fontSize={["28px", "30px", "33px", "33px"]} fontWeight={"600"}>STS 2 METER UPGRADE – GOES LIVE ON 31 OCTOBER, 2024!  </Text>
                            <Text mt="20px" fontWeight={"700"}>QUICK STEPS TO GETTING YOUR KCT (KEY CHANGE TOKEN) </Text>

                            <Text mt="10px" >- Input your meter number</Text>
                            <Text>- You will receive two (2) sets of 20-digit Key Change Tokens (KCTs)</Text>
                            <Text>- Input the KCTs, one after the other, to successfully complete your upgrade.</Text>

                            <Text fontWeight={"600"} mt="32px">Watch out for more information!  </Text>
                            <Text fontWeight={"600"} mt="32px">For further enquiries, kindly contact our Customer Experience Center:
                                customercare@ekedp.com or 0708 067 1170, 0700 123 5666. </Text>
                        </Box> */}

                        {
                            ErrorMSG.status === true && (

                                <Text mt={"12px"} color="red" textAlign={"center"}>{ErrorMSG?.msg}</Text>
                            )
                        }
                        {
                            RedirectMsg.status === true && (

                                <Box bg={"gray"} py="15px" px="15px" mt="32px" borderRadius={"8px"}>
                                    <Text color="#fff" textAlign={"center"}>Hello, your meter {`(${Data?.meternumber})`} is outdated and can no longer be upgraded.
                                        Kindly click the link below to request another meter</Text>
                                    <a href='https://ekedp.com/order-meter' target='_blank'>

                                        <Text fontWeight={"700"} color="navy" textAlign={"center"} mt="10px">Order A New Meter</Text>
                                    </a>
                                </Box>

                            )
                        }
                    </Box>



                </Box>


            </Flex>


            <DetailModal isOpen={isOpen} onClose={onClose} Data={Data} />
        </Box>
    )
}
